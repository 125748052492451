import React from 'react';
import './App.css';
import {initializeConnector, useWeb3React, Web3ReactProvider} from "@web3-react/core";
import {MetaMask} from "@web3-react/metamask";
import Chain from "./components/Chain";

function App() {
    const Metamask = initializeConnector<MetaMask>((actions) => new MetaMask({ actions }))
    const connectors = {
        MetaMask: Metamask
    }

    return (
      <Web3ReactProvider connectors={[Metamask]}>
          <div className="App">
          <div className="navbar">
              <div className="menu-icon">
                  <div className="bar"></div>
                  <div className="bar"></div>
                  <div className="bar"></div>
              </div>
              <img src="visuals/bananachimp.png" alt="Description of the image" />
              <div className="nav-links">
                  <a href="#home">Home</a>
                  <a href="https://app.withmantra.com/market/collection/0xec10d3091aBFFcC89f0CcA5aE90842f5628BfB56?chain_id=2000&auctionType=fixed&sort=2&statuses=created">Mantra</a>
              </div>
          </div>
          <div className="gradient-container">
              <div className="additional-content">
                  <p>WELCOME TO THE</p>
                  <h2>DOGECHAIN CHIMPS</h2>
                  <p> </p>
              </div>
              <section className="wavy">
                  <div className="additional-content">
                      <p>Hello frens</p>
                  </div>
                  <div className="content-container">
                      <div className="grid-item">
                          <h2>Collection info:</h2>
                          <p>&#x1F34C; 3666 Chimps</p>
                          <p>&#x1F34C; 8 legendary Chimpies</p>
                          <p>&#x1F34C; Mint price: 10.0 wDoge</p>
                          <p>&#x1F34C; <a href="https://explorer.dogechain.dog/token/0xec10d3091aBFFcC89f0CcA5aE90842f5628BfB56/token-transfers">Explorer link</a></p>
                      </div>
                      <div className="grid-item">
                          <h2>Chimp previews:</h2>
                          <div className="slider">
                              <div className="slide-track">
                                  <img src="visuals/chimpie.png" alt="Description of the image" />
                                  <img src="visuals/bananachimp.png" alt="Description of the image" />
                                  <img src="visuals/brickchimp.png" alt="Description of the image" />
                                  <img src="visuals/greenchimp.png" alt="Description of the image" />
                                  <img src="visuals/robochimp.png" alt="Description of the image" />
                                  <img src="visuals/kingchimp.png" alt="Description of the image" />
                                  <img src="visuals/mcdonaldchimp.png" alt="Description of the image" />
                                  <img src="visuals/barrelchimp.png" alt="Description of the image" />
                              </div>
                          </div>
                          <p>
                              <Chain />
                          </p>
                          <p>
                            <a target={'_blank'} href="https://app.withmantra.com/market/collection/0xec10d3091aBFFcC89f0CcA5aE90842f5628BfB56?chain_id=2000&auctionType=fixed&sort=2&statuses=created" className="button">Check the market!</a>
                          </p>
                      </div>
                  </div>

                  <div className="additional-content">
                      <p></p>
                  </div>
              </section>
              <div className="additional-content">
                  <p>SOCIALS:</p>
                  <p>
                      <a className="fa fa-medium" href="https://app.withmantra.com/market/collection/0xec10d3091aBFFcC89f0CcA5aE90842f5628BfB56?chain_id=2000&auctionType=fixed&sort=2&statuses=created"></a>
                      <a className="fa fa-twitter" href="https://twitter.com/ChimpsDogechain"></a>
                      <a className="fa fa-telegram" href="https://t.me/dogechainchimps"></a>
                  </p>
              </div>

          </div>
        </div>
      </Web3ReactProvider>
    );
}

export default App;
