import {useWeb3React} from "@web3-react/core";
import React, {useEffect, useState} from "react";
import {Contract, ethers} from "ethers";
import nftABI from "../ABI/dcchimps.json";
import adoptionCentreABI from "../ABI/dcchimps-adoptioncentre.json";
import {Button, InputGroup} from "react-bootstrap";
import InputGroupText from "react-bootstrap/InputGroupText";

let config = {
    nft: '0xec10d3091aBFFcC89f0CcA5aE90842f5628BfB56',
    wallet: '0xC430A1EC98F00e1E702f22caC7eA22a0B0a81e12',
    adoptionCentre: '0x0C9B465aC0240041D8e322E7ebA7d6A96938E697',
}

function Chain () {
    const { isActive, chainId, accounts, provider, connector } = useWeb3React();
    const [connected, setConnected] = useState(false)
    const [connecting, setConnecting] = useState(false)
    const [nft, setNft] = useState<Contract>()
    const [adoptionCentre, setAdoptionCentre] = useState<Contract>()
    const [costs, setCosts] = useState(0)
    const [currency, setCurrency] = useState('');
    const [available, setAvailable] = useState(0);
    const [amount, setAmount] = useState(1);
    const [minted, setMinted] = useState(false);

    const activate = async (_chain : any) => {
        await connector.activate(_chain)
    }

    const connect = async () => {
        if(!isActive) {
            return;
        }
        setConnecting(true)
        let tmpNft;
        let tmpAdoptionCentre;
        switch(chainId) {
            case 2000:
                tmpNft = new Contract(config.nft, nftABI, provider?.getSigner())
                tmpAdoptionCentre = new Contract(config.adoptionCentre, adoptionCentreABI, provider?.getSigner());
                setCurrency('WDOGE');
                break
        }
        if(tmpNft) {
            setNft(tmpNft)
            setAvailable(await tmpNft.balanceOf(config.wallet))
        }

        setAdoptionCentre(tmpAdoptionCentre)
        if(tmpAdoptionCentre && accounts) {
            setCosts(await tmpAdoptionCentre.getCosts())
        }
        setConnecting(false)
        setConnected(true)
    }

    useEffect( () => {
        if(isActive && provider && !connected && !connecting) {
            connect();
        }
    })

    const mint = async () => {
        setMinted(false)
        if(adoptionCentre) {
            try {
                let tx = await adoptionCentre.mint(amount, {value: (costs * amount).toString()})
                await tx.wait();
                setMinted(true)
            } catch (e) {

            }
        }
    };

    const amountSubstract = async () => {
        setAmount(Math.max(amount - 1, 1));
    }

    const amountAdd = async () => {
        setAmount(Math.min(amount + 1, 20));
    }

    const approve = async () => {
        if(nft) {
            await nft.setApprovalForAll(config.adoptionCentre, true);
        }
    }

    return <div>
        { connected && chainId === 2000 ?
            <>
                <p><strong>{available.toString()}</strong> chimps looking for a new owner. {ethers.utils.formatEther(costs.toString())} {currency} each.</p>
                <InputGroup className={'mint'}>
                    <Button className={'button'} onClick={amountSubstract}>-</Button>
                    &nbsp; {amount} &nbsp;
                    <Button className={'button'} onClick={amountAdd}>+</Button>
                    <Button className={'button'} variant={"danger"} onClick={mint}>Adopt</Button>
                </InputGroup>

                { minted ? <p>You've received your Chimps! Bananas...</p> : <></> }
            </> : <>
                <a className='button' onClick={activate}>Connect Metamask</a>
            </>
        }
    </div>
}

export default Chain;